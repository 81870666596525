@if (!hideFilterHeader) {
  <div class="card w-100 mb-4">
    <div class="card-body p-2" *ngIf="!resetFilters">
      <app-filters
        [filters]="paramFilters"
        [searchPlaceholder]="searchPlaceholder"
        (searchClick)="onSearchClick($event)"
        [allowAction]="true"
        [showCreateButton]="showCreateButton"
        [showRefreshButton]="showRefreshButton"
        [isBtnBelow]="isBtnBelow"
        [sendIntercity]="sendIntercity"
        [linkIcon]="linkIcon"
        linkText="{{ linkText }}"
        [showAddDropDown]="showAddDropDown"
        [addDropDownData]="addDropDownData"
        link="{{ link }}"
        [queryParams]="queryParams"
        title="{{ title }}"
        [filtersPerRow]="filtersPerRow"
        [hideFilter]="hideFilter || paramFilters.length === 0"
        [maxFiltersOnFirstRow]="maxFiltersOnFirstRow"
        [searchable]="searchable"
        [crmItemsExport]="crmItemsExport"
        [itemsExport]="itemsExport"
        [isActionOnFilterApply]="!!infoImageUrl"
        [enableTableAction]="enableTableAction"
        [allowedTableActions]="allowedTableActions"
        [showZoneButton]="showZoneButton"
        [exportDisabledOnNoRecord]="exportDisabledOnNoRecord"
        [isRecordsFound]="!isLocalTable ? !!(itemList$ | async)?.length : !!localItems.length"
        (exportClick)="getBtnClickType($event)"
        (tableAction)="onEmitAction($event)"
        (buttonClick)="emitButtonEvent($event)"></app-filters>
    </div>
  </div>
}

<div [class]="!hideStyles ? 'card w-100' : ''">
  <div [class]="!hideStyles ? 'card-body pt-2 px-4 pb-3' : ''">
    <div class="pb-4">
      <div
        class="table-responsive"
        [ngClass]="[
          alignment || '',
          !(!isLocalTable && !(itemList$ | async)?.length && !isLoading) && !notFoundMessage
            ? 'table-responsive-min-height'
            : ''
        ]">
        <ng-container *ngIf="isLoading; else tableTempRef">
          <app-loader></app-loader>
        </ng-container>
        <!-- [ngClass]="column.className || column.headingClass || ''"> -->
        <ng-template #tableTempRef>
          <table class="table">
            <thead>
              <tr>
                <th
                  title="{{ column.value | translate }}"
                  *ngFor="let column of displayedColumnsViewArray"
                  class="col-{{ column.key }}"
                  [ngClass]="
                    (column.className ? column.className + ' ' : '') +
                    (column.headingClass ? column.headingClass : '')
                  ">
                  <div class="custom-form-check" *ngIf="column.type === 'checkbox'">
                    <div class="custom-form-check">
                      <input
                        type="checkbox"
                        (change)="onSelectAllChanged($event)"
                        [id]="column.id"
                        class="custom-form-check-input" />
                      <label class="custom-form-check-label" [for]="column.id"></label>
                    </div>
                  </div>
                  {{ column.value | translate }}
                  <ng-container *ngIf="column.sortable">
                    <i
                      class="pointer-event"
                      [class.active]="sortKey === (column.sortKey || column.key)"
                      class=""
                      [ngClass]="
                        sortKey === (column.sortKey || column.key) && sortOrder.toLowerCase()
                          ? sortOrder.toLowerCase()
                          : ''
                      "
                      (click)="sortTable(column)"></i>
                  </ng-container>
                </th>
              </tr>
            </thead>
            <tbody class="border-0">
              <ng-container *ngIf="isLocalTable; else defaultTableRowTempRef">
                <tr
                  *ngFor="
                    let element of localItems
                      | slice: (pageIndex - 1) * pageSize : pageIndex * pageSize;
                    let i = index
                  ">
                  <ng-container *ngFor="let column of displayedColumnsViewArray">
                    <ng-container
                      [ngTemplateOutlet]="tableColumnElemRef"
                      [ngTemplateOutletContext]="{
                        column: column,
                        element: element,
                        index: i
                      }"></ng-container>
                  </ng-container>
                </tr>
              </ng-container>

              <ng-template #defaultTableRowTempRef>
                <tr *ngFor="let element of itemList$ | async; let i = index">
                  <ng-container *ngFor="let column of displayedColumnsViewArray">
                    <ng-container
                      [ngTemplateOutlet]="tableColumnElemRef"
                      [ngTemplateOutletContext]="{
                        column: column,
                        element: element,
                        index: i
                      }"></ng-container>
                  </ng-container>
                </tr>
              </ng-template>
            </tbody>
            <!-- editable table add button start -->
            <tfoot *ngIf="showEditableTableFooter" class="editable-footer">
              <tr>
                <td *ngFor="let col of displayedColumnsViewArray; let i = index">
                  @if (isAddNewEnabled) {
                    <ng-container
                      [ngTemplateOutlet]="editableTableColumnElemRef"
                      [ngTemplateOutletContext]="{
                        column: col,
                        index: i
                      }"></ng-container>
                  } @else if (i === displayedColumnsViewArray.length - 1) {
                    <button
                      class="btn btn-primary btn-icon-text btn-140"
                      type="button"
                      (click)="onAddNewEnabled()">
                      {{ 'COMMON.TEXT.NEW' | translate }}
                    </button>
                  }

                  <!-- <ng-container *ngIf="shouldShowFooter(i); else emptyCell">
                    {{ getFooterValue(i) }}
                  </ng-container>
                  <ng-template #emptyCell></ng-template> -->
                </td>
              </tr>
            </tfoot>
            <!-- editable table add button end -->
            <tfoot *ngIf="showTableFooter && localItems?.length > 0">
              <tr>
                <td *ngFor="let col of displayedColumnsViewArray; let i = index">
                  <ng-container *ngIf="shouldShowFooter(i); else emptyCell">
                    {{ getFooterValue(i) }}
                  </ng-container>
                  <ng-template #emptyCell></ng-template>
                </td>
              </tr>
            </tfoot>
          </table>
        </ng-template>
      </div>
      <div 
      class="d-flex justify-content-center align-items-center empty-data"
      *ngIf="!isLocalTable && !(itemList$ | async)?.length && !isLoading">
   <app-empty-state
     [showInfoImage]="showInfoImage"
     [infoMessage]="infoMessage"
     [image]="infoImageUrl"
     [text]="
       hasFiltersOrSearch() && hasActiveFiltersOrSearch()
         ? ('MSGS.GENERAL.NO_RECORD_FOUND' | translate) 
         : (notFoundMessage || 'MSGS.GENERAL.NO_RESULTS_FOUND_GENERIC' | translate)
     ">
   </app-empty-state>
 </div>
      <div
        class="d-flex justify-content-center align-items-center empty-data"
        *ngIf="isLocalTable && localItems.length === 0">
        <app-empty-state
          [showInfoImage]="showInfoImage"
          [image]="infoImageUrl"
          [text]="
            notFoundMessage || 'MSGS.GENERAL.NO_RESULTS_FOUND_GENERIC' | translate
          "></app-empty-state>
      </div>
    </div>
    @if (!hidePagination && totalElements > 0) {
      <div class="">
        <app-pagination
          [page]="pageIndex"
          [size]="pageSize"
          [totalElements]="totalElements"
          (pageChange)="pageChanged($event)"
          [totalPages]="totalPages"
          [totalPaginatedElementCount]="
            isLocalTable ? (pageIndex - 1) * pageSize : itemList$.value.length
          "></app-pagination>
      </div>
    }

    <ng-template #tableColumnElemRef let-column="column" let-element="element" let-i="index">
      <td
        [ngSwitch]="column.type"
        class="col-{{ column.key }} cursor-pointer"
        (click)="emitEvent(element, column, $event, 'from_td')"
        [ngClass]="[column?.className || '', element?.isChecked ? 'row-selected' : '']">
        <ng-container *ngIf="element">
          <ng-container *ngSwitchCase="'checkbox'">
            <div class="custom-form-check">
              <input
                type="checkbox"
                (change)="onRowCheckChanged(element)"
                [checked]="element.isChecked"
                [id]="element.id"
                class="custom-form-check-input" />
              <label class="custom-form-check-label" [for]="element.id"></label>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'text'">
            @if (element?.editableView) {
              <div class="form-field">
                <input
                  [type]="column.type"
                  [disabled]="!column?.editable || false"
                  [(ngModel)]="backupFormData[column.outputKey]"
                  class="form-control"
                  [ngClass]="{ disabled: !column?.editable }" />
              </div>
            } @else {
              <span [title]="getTitle(element, column)">
                {{ constructNestedObject(element, column) || '-' | localize }}
              </span>
            }
          </ng-container>
          <ng-container *ngSwitchCase="'datetime'">
            {{
              (constructNestedObject(element, column) | date: column.format || 'dd/MM/yyyy') || '-'
            }}
          </ng-container>
          <ng-container *ngSwitchCase="'number'">
            {{ constructNestedObject(element, column) || '-' }}
          </ng-container>

          <ng-container *ngSwitchCase="'image'">
            @if (constructNestedObject(element, column)) {
              <img src="{{ constructNestedObject(element, column) }}" alt="logo" />
            } @else {
              -
            }
          </ng-container>

          <a
            *ngSwitchCase="'hyper_link'"
            (click)="emitEvent(element, column, $event)"
            [ngClass]="{
              'cursor-disabled': column.disabled,
              'text-decoration-none': !element.booking
            }">
            {{ constructNestedObject(element, column) | localize }}
          </a>
          <!-- <app-aws-image *ngSwitchCase="'image'" [column]="column" [element]="element"></app-aws-image> -->
          @if (element?.editableView) {
            <button
              type="button"
              *ngSwitchCase="'button'"
              [ngClass]="column.class || ''"
              [class.cursor-disabled]="column.disabled || false"
              (click)="
                emitEvent(
                  'fromEditableView',
                  column,
                  column.iconCancelClass ? 'cancel_editable' : 'editable',
                  '',
                  i
                )
              "
              [disabled]="
                column.disabled || column.callback
                  ? constructNestedObject('element', column)
                  : false
              ">
              <ng-container *ngIf="column.iconCancelClass">
                <img src="assets/images/cross.svg" alt="" />
              </ng-container>
              <ng-container *ngIf="column.iconSubmitClass">
                <img src="assets/images/checkmark.svg" alt="" />
              </ng-container>
            </button>
          } @else {
            <button
              type="button"
              *ngSwitchCase="'button'"
              [ngClass]="column.class || ''"
              [class.cursor-disabled]="column.disabled || false"
              (click)="
                emitEvent(element, column, column.actionKey === 'edit' ? 'editView' : null, '', i)
              "
              [disabled]="
                column.disabled || column.callback ? constructNestedObject(element, column) : false
              ">
              <ng-container *ngIf="column.iconClass">
                <i
                  [ngClass]="[
                    column.iconClass,
                    column.callback && constructNestedObject(element, column)
                      ? 'icon-disable-color'
                      : ''
                  ]"></i>
              </ng-container>
              <ng-container *ngIf="!column.iconClass">
                {{ column.callback ? constructNestedObject(element, column) : column.value }}
              </ng-container>
            </button>
          }
          <div *ngSwitchCase="'multiple-btn'">
            @if (element?.editableView) {
              <div class="d-flex gap-1 f-16 ps-2 justify-content-center">
                <ng-container *ngFor="let button of column.buttons">
                  <button
                    *ngIf="button.key && button.iconClass"
                    type="button"
                    [ngClass]="button.class || ''"
                    [class.cursor-disabled]="button.disabled || false"
                    (click)="multipleButtonsHandler('edit', button, i)"
                    [disabled]="button.disabled || false">
                    <ng-container *ngIf="button.iconClass">
                      <ng-container *ngIf="button.iconCancelClass">
                        <img src="assets/images/cross.svg" alt="" />
                      </ng-container>
                      <ng-container *ngIf="button.iconSubmitClass">
                        <img src="assets/images/checkmark.svg" alt="" />
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!button.iconClass">
                      {{ button.value }}
                    </ng-container>
                  </button>
                </ng-container>
              </div>
            } @else {
              <div class="d-flex gap-1 f-16 ps-2 justify-content-center">
                <ng-container *ngFor="let button of column.buttons">
                  <button
                    *ngIf="button.key && button.iconClass"
                    type="button"
                    [ngClass]="button.class || ''"
                    [class.cursor-disabled]="button.disabled || false"
                    (click)="multipleButtonsHandler(element, button, i)"
                    [disabled]="button.disabled || false">
                    <ng-container *ngIf="button.iconClass">
                      <i
                        [ngClass]="[
                          button.iconClass,
                          button.callback && constructNestedObject(element, button)
                            ? 'icon-disable-color'
                            : ''
                        ]"></i>
                    </ng-container>
                    <ng-container *ngIf="!button.iconClass">
                      {{ button.value }}
                    </ng-container>
                  </button>
                </ng-container>
              </div>
            }
          </div>
          <div *ngSwitchCase="'badge'">
            <div
              class="badge-status"
              [class.active]="element[column.key] === true"
              [class.in-active]="
                element[column.key] !== true && getColumnKeyType(element[column.key]) === 'boolean'
              ">
              {{
                column.map
                  ? column.map[element[column.key]]
                  : constructNestedObject(element, column)
              }}
            </div>
          </div>
          <ng-container *ngSwitchCase="'status'">
            <status
              [element]="element"
              [data]="element"
              [column]="column"
              [options]="column?.options || statusArray"
              [canUpdateStatus]="canUpdateStatus"
              (updatedStatus)="
                updateStatus(
                  $event.status,
                  $event.element,
                  $event.data,
                  column?.endpoint,
                  column?.success,
                  column?.error,
                  column?.successSecond,
                  column?.apiCallType
                )
              "></status>
          </ng-container>
          <i *ngSwitchCase="'icon'" [ngClass]="column.class || ''"></i>
          <div
            *ngSwitchCase="'template'"
            [ngClass]="column.class ? column.class : ''"
            (click)="emitEvent(element, column, $event)"
            [innerHtml]="
              column.callback ? constructNestedObject(element, column) : column.value
            "></div>
          <ng-container *ngSwitchCase="'toggle'">
            <app-toggle-switch
              *ngIf="(sharedDataService?.loadingBarStatus | async) === false"
              [disabled]="column?.disabled || false"
              class="w-100"
              [value]="element[column.key]"
              [showLabel]="column.showLabel || false"
              [options]="column.map"
              (onCheckedChanged)="emitEvent(element, column, $event)"></app-toggle-switch>
          </ng-container>
          <ng-container *ngSwitchCase="'templateRef'">
            <ng-container
              [ngTemplateOutlet]="column.templateRef"
              [ngTemplateOutletContext]="{
                column: column,
                element: element,
                index: i
              }"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'component'">
            <ng-container
              *ngxComponentOutlet="
                column.component;
                context: { column: column, element: element }
              "></ng-container>
          </ng-container>
        </ng-container>
      </td>
      <!-- <td>
          @if (element) {
            @switch(column.type){
              @case('text'){
                {{constructNestedObject(element, column)}}
              }
            }
          }
      </td> -->
    </ng-template>
    <!-- editable table table start -->
    <ng-template
      #editableTableColumnElemRef
      let-column="column"
      let-element="element"
      let-i="index">
      <!-- (click)="emitEvent(element, column, $event, 'from_td')" -->
      <td
        [ngSwitch]="column.type"
        class="col-{{ column.key }} cursor-pointer"
        [ngClass]="[column?.className || '']">
        <ng-container *ngIf="column">
          <ng-container *ngSwitchCase="'text'">
            <div class="form-field">
              <input
                [type]="column.type"
                [disabled]="!column?.editable || false"
                [(ngModel)]="backupFormData[column.outputKey]"
                class="form-control"
                [ngClass]="{ disabled: !column?.editable }" />
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'status'">
            <!-- [element]="element"
            [data]="element" -->
            <status
              [element]="element"
              [data]="element"
              [column]="column"
              [options]="column?.options || statusArray"
              [canUpdateStatus]="canUpdateStatus"
              (updatedStatus)="
                updateStatus(
                  $event.status,
                  $event.element,
                  $event.data,
                  column?.endpoint,
                  column?.success,
                  column?.error,
                  column?.successSecond
                )
              "></status>
          </ng-container>
          <ng-container *ngSwitchCase="'multiple-btn'">
            <!-- <button
              type="button"
              [ngClass]="column.class || ''"
              [class.cursor-disabled]="column.disabled || false"
              (click)="
                emitEvent(
                  'element',
                  column,
                  column.iconCancelClass ? 'cancel_editable' : 'editable'
                )
              "
              [disabled]="
                column.disabled || column.callback
                  ? constructNestedObject('element', column)
                  : false
              ">
              <ng-container *ngIf="column.iconCancelClass">
                <img src="assets/images/cross.svg" alt="" />
              </ng-container>
              <ng-container *ngIf="column.iconSubmitClass">
                <img src="assets/images/checkmark.svg" alt="" />
              </ng-container>
            </button> -->
            <div class="d-flex gap-1 f-16 ps-2 justify-content-center">
              <ng-container *ngFor="let button of column.buttons">
                <button
                  *ngIf="button.key && button.iconClass"
                  type="button"
                  [ngClass]="button.class || ''"
                  [class.cursor-disabled]="button.disabled || false"
                  (click)="multipleButtonsHandler('add', button)"
                  [disabled]="button.disabled || false">
                  <ng-container *ngIf="button.iconClass">
                    <!-- <i
                      [ngClass]="[
                        button.iconClass,
                        button.callback && constructNestedObject(element, button)
                          ? 'icon-disable-color'
                          : ''
                      ]"></i> -->
                    <ng-container *ngIf="button.iconCancelClass">
                      <img src="assets/images/cross.svg" alt="" />
                    </ng-container>
                    <ng-container *ngIf="button.iconSubmitClass">
                      <img src="assets/images/checkmark.svg" alt="" />
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!button.iconClass">
                    {{ button.value }}
                  </ng-container>
                </button>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </td>
    </ng-template>
    <!-- editable table table end -->
  </div>
</div>

<app-confirm-dialog
  [closeText]="'GENERAL.BUTTONS.CLOSE' | translate"
  [confirmText]="'GENERAL.BUTTONS.CONFIRM' | translate"
  [message]="message | translate"
  [extraIcon]="extraIcon"
  (closeDialog)="processModalClose($event)"></app-confirm-dialog>
